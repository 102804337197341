export const CricketIcon = ({ className, fill }: SportIcon) => {
  return (
    <svg
      className={className}
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>cricket icon</title>
      <path d="M4.51172 2.625H5.98828V4.26562H4.51172V2.625Z" fill={fill} />
      <path
        d="M5.98828 1.06641C5.98828 0.870602 5.9105 0.682817 5.77204 0.544363C5.63359 0.405908 5.4458 0.328125 5.25 0.328125C5.0542 0.328125 4.86641 0.405908 4.72796 0.544363C4.5895 0.682817 4.51172 0.870602 4.51172 1.06641V1.96875H5.98828V1.06641Z"
        fill={fill}
      />
      <path d="M5.98828 6.23438V4.92188H4.51172V6.23438L5.25 9.51562L5.98828 6.23438Z" fill={fill} />
      <path
        d="M11.1562 4.26562H10.5C10.326 4.26562 10.159 4.33477 10.036 4.45784C9.91289 4.58091 9.84375 4.74783 9.84375 4.92188V18.4626C9.8437 19.2191 10.099 19.9535 10.5683 20.5469C10.5994 20.5858 10.639 20.6173 10.6839 20.639C10.7289 20.6606 10.7782 20.6718 10.8281 20.6718C10.878 20.6718 10.9273 20.6606 10.9723 20.639C11.0173 20.6173 11.0568 20.5858 11.088 20.5469C11.5573 19.9535 11.8125 19.2191 11.8125 18.4626V4.92188C11.8125 4.74783 11.7434 4.58091 11.6203 4.45784C11.4972 4.33477 11.3303 4.26562 11.1562 4.26562Z"
        fill={fill}
      />
      <path
        d="M15.4219 4.26562H14.7656C14.5916 4.26562 14.4247 4.33477 14.3016 4.45784C14.1785 4.58091 14.1094 4.74783 14.1094 4.92188V18.4626C14.1093 19.2191 14.3646 19.9535 14.8339 20.5469C14.865 20.5858 14.9046 20.6173 14.9496 20.639C14.9946 20.6606 15.0438 20.6718 15.0938 20.6718C15.1437 20.6718 15.1929 20.6606 15.2379 20.639C15.2829 20.6173 15.3225 20.5858 15.3536 20.5469C15.8229 19.9535 16.0782 19.2191 16.0781 18.4626V4.92188C16.0781 4.74783 16.009 4.58091 15.8859 4.45784C15.7628 4.33477 15.5959 4.26562 15.4219 4.26562Z"
        fill={fill}
      />
      <path
        d="M19.6875 4.26562H19.0312C18.8572 4.26562 18.6903 4.33477 18.5672 4.45784C18.4441 4.58091 18.375 4.74783 18.375 4.92188V18.4626C18.375 19.2191 18.6302 19.9535 19.0995 20.5469C19.1307 20.5858 19.1702 20.6173 19.2152 20.639C19.2602 20.6606 19.3095 20.6718 19.3594 20.6718C19.4093 20.6718 19.4586 20.6606 19.5036 20.639C19.5485 20.6173 19.5881 20.5858 19.6192 20.5469C20.0885 19.9535 20.3438 19.2191 20.3438 18.4626V4.92188C20.3438 4.74783 20.2746 4.58091 20.1515 4.45784C20.0285 4.33477 19.8615 4.26562 19.6875 4.26562Z"
        fill={fill}
      />
      <path
        d="M19.0312 1.64062H16.0781C15.7157 1.64062 15.4219 1.93444 15.4219 2.29688V2.95312C15.4219 3.31556 15.7157 3.60938 16.0781 3.60938H19.0312C19.3937 3.60938 19.6875 3.31556 19.6875 2.95312V2.29688C19.6875 1.93444 19.3937 1.64062 19.0312 1.64062Z"
        fill={fill}
      />
      <path
        d="M14.1094 1.64062H11.1562C10.7938 1.64062 10.5 1.93444 10.5 2.29688V2.95312C10.5 3.31556 10.7938 3.60938 11.1562 3.60938H14.1094C14.4718 3.60938 14.7656 3.31556 14.7656 2.95312V2.29688C14.7656 1.93444 14.4718 1.64062 14.1094 1.64062Z"
        fill={fill}
      />
      <path
        d="M4.2285 17.5635L3.78487 18.0068C3.75461 18.0381 3.7184 18.0631 3.67837 18.0803C3.63833 18.0975 3.59528 18.1066 3.55171 18.107C3.50814 18.1073 3.46493 18.099 3.42461 18.0825C3.38428 18.066 3.34765 18.0417 3.31684 18.0109C3.28603 17.9801 3.26167 17.9434 3.24517 17.9031C3.22867 17.8628 3.22037 17.8196 3.22074 17.776C3.22112 17.7324 3.23018 17.6894 3.24737 17.6493C3.26457 17.6093 3.28957 17.5731 3.32091 17.5428L3.76453 17.0995C3.38569 16.8295 2.9234 16.7026 2.45979 16.7412C1.99618 16.7798 1.56127 16.9815 1.23231 17.3104C0.903353 17.6394 0.701653 18.0743 0.663039 18.5379C0.624426 19.0015 0.7514 19.4638 1.02141 19.8427L1.46503 19.399C1.5266 19.3375 1.61009 19.303 1.69713 19.303C1.78417 19.303 1.86764 19.3376 1.92916 19.3992C1.99069 19.4608 2.02524 19.5443 2.02521 19.6313C2.02518 19.7183 1.99057 19.8018 1.929 19.8633L1.48537 20.3066C1.86422 20.5766 2.32651 20.7036 2.79011 20.665C3.25372 20.6264 3.68864 20.4247 4.01759 20.0957C4.34655 19.7668 4.54825 19.3318 4.58686 18.8682C4.62548 18.4046 4.4985 17.9423 4.2285 17.5635ZM3.08892 18.7031L2.62495 19.167C2.56307 19.2268 2.48018 19.2599 2.39415 19.2591C2.30812 19.2584 2.22582 19.2239 2.16498 19.163C2.10414 19.1022 2.06963 19.0199 2.06889 18.9339C2.06814 18.8478 2.10121 18.765 2.16098 18.7031L2.62495 18.2391C2.65522 18.2078 2.69143 18.1828 2.73146 18.1656C2.77149 18.1484 2.81455 18.1393 2.85812 18.1389C2.90169 18.1386 2.94489 18.1469 2.98522 18.1634C3.02554 18.1799 3.06218 18.2042 3.09299 18.235C3.1238 18.2659 3.14816 18.3025 3.16466 18.3428C3.18116 18.3831 3.18946 18.4263 3.18908 18.4699C3.1887 18.5135 3.17965 18.5565 3.16245 18.5966C3.14526 18.6366 3.12026 18.6728 3.08892 18.7031Z"
        fill={fill}
      />
      <path
        d="M6.93525 6.83237L6.57727 6.60629H6.57563L5.57025 11.0799C5.554 11.1528 5.51342 11.2179 5.45521 11.2645C5.39699 11.3112 5.32461 11.3366 5.25 11.3366C5.17539 11.3366 5.10301 11.3112 5.04479 11.2645C4.98658 11.2179 4.946 11.1528 4.92975 11.0799L3.92438 6.60498H3.92274L3.56475 6.83106C3.37724 6.94949 3.22279 7.11351 3.11584 7.3078C3.00888 7.50208 2.9529 7.72031 2.95313 7.94209V16.1006C3.45466 16.1629 3.92747 16.3689 4.31455 16.6939C4.70163 17.0188 4.98646 17.4488 5.13469 17.932C5.28293 18.4151 5.28824 18.9309 5.14999 19.417C5.01174 19.9031 4.73584 20.3388 4.35553 20.6717H6.89063C7.06467 20.6717 7.23159 20.6026 7.35466 20.4795C7.47774 20.3564 7.54688 20.1895 7.54688 20.0155V7.94209C7.54688 7.72053 7.49079 7.50257 7.38385 7.30853C7.2769 7.11449 7.12258 6.95068 6.93525 6.83237Z"
        fill={fill}
      />
    </svg>
  );
};
