import { TonIcon } from "@/components/Icons/TonIcon";
import { LoadingSpinner } from "@/components/LoadingSpinner";
import { useErrorModalContext } from "@/contexts/ErrorModalContext";
import { useUserContext } from "@/contexts/UserContext";
import { useSoccerRounds } from "@/hooks/soccer/useSoccerRounds";
import type { RomanApiError } from "@/lib/net/romanApi";
import { getErrorMessage } from "@/utils/error";
import { formatNanoTon } from "@/utils/formatNanoTon";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SoccerRoundsCard } from "./SoccerRoundsCard";
import { LEAGUES, type League, SPORTS, type Sport } from "./constants";

export const HomePage = () => {
  const navigate = useNavigate();
  const [selectedLeague, setSelectedLeague] = useState<League>(LEAGUES[0]);
  const [selectedSport, setSelectedSport] = useState<Sport>(SPORTS[0]);
  const user = useUserContext();

  const { openModal } = useErrorModalContext();
  const handleError = useCallback(
    (error: RomanApiError) => {
      const message = getErrorMessage(error);
      openModal("Error getting rounds", message);
    },
    [openModal],
  );
  const { data, isLoading } = useSoccerRounds(handleError);

  const filteredRounds = data?.rounds?.filter((round) => round.seasonId === selectedLeague.seasonId) ?? [];
  const displayRounds = filteredRounds.length ? filteredRounds : null;

  return (
    <div className={`${selectedSport.id !== 1 ? "min-h-screen flex flex-col" : ""}`}>
      <header className="flex flex-col px-2 py-4">
        <div className="flex items-center justify-center mb-3 gap-2">
          <div className="flex justify-center items-center gap-[5px] -translate-x-3">
            {SPORTS.map((sport) => (
              <span
                className="bg-button-background p-1.5 rounded-3xl hover:cursor-pointer"
                onClick={() => setSelectedSport(sport)}
                key={sport.id}
              >
                <sport.icon fill={selectedSport.id === sport.id ? "#5fe311" : "#474747"} />
              </span>
            ))}
          </div>

          <div className="flex justify-center items-center gap-2.5">
            <div
              className="flex items-center justify-center gap-4 border border-chicago-700 rounded-2xl px-2 py-1.5 hover:cursor-pointer"
              onClick={() => navigate("/balance")}
              onKeyDown={(e) => {
                if (e.key === "enter") navigate("/balance");
              }}
            >
              <div className="flex justify-center items-center gap-1">
                <TonIcon />
                <button type="button" className=" text-sm text-white">
                  {formatNanoTon(user.nanoTon)}
                </button>
              </div>
              <img className="bg-button-background p-1.5 rounded-3xl" src="/assets/Union.svg" alt="plus icon" />
            </div>
            <img
              onClick={() => navigate("/my-page")}
              src="/assets/account-icon.svg"
              alt="Account"
              className="hover:cursor-pointer"
            />
          </div>
        </div>
        {selectedSport.id === 1 && (
          <div className="flex gap-1.5 overflow-x-scroll custom-scrollbar">
            {LEAGUES.map((league) => (
              <LeaguePill
                onClick={() => setSelectedLeague(league)}
                isSelected={selectedLeague.seasonId === league.seasonId}
                league={league.name}
                key={league.seasonId}
              />
            ))}
          </div>
        )}
      </header>
      {isLoading ? (
        <LoadingSpinner />
      ) : displayRounds && selectedSport.id === 1 ? (
        displayRounds.map((round) => <SoccerRoundsCard key={round.id} round={round} />)
      ) : (
        <div className="flex-1 flex items-center justify-center h-[70vh]">
          <div className="flex flex-col justify-center items-center text-center">
            <img className="mb-4" src="/assets/thumba-coming-soon.svg" alt="thumba img" />
            <p className="text-xs text-icon">No tournaments in this league</p>
          </div>
        </div>
      )}
    </div>
  );
};

const LeaguePill = ({ league, onClick, isSelected }: { league: string; onClick: () => void; isSelected: boolean }) => {
  return (
    <div
      onClick={onClick}
      className={`flex items-center justify-center py-2 px-3 rounded-[30px] min-w-max hover:cursor-pointer
        ${isSelected ? "bg-gradient-to-r from-gradient-yellow to-gradient-green text-black" : "bg-button-background text-icon"}`}
    >
      <p className="text-xs">{league}</p>
    </div>
  );
};
