import type { FantasyPoint } from "@/hooks/soccer/useRecentFantasyPoints";
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Tooltip } from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { format } from "date-fns";
import type React from "react";
import { Bar } from "react-chartjs-2";

// Register required Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend, ChartDataLabels, annotationPlugin);

interface PlayerStatBarChartProps {
  data: FantasyPoint[];
}

const PlayerStatBarChart: React.FC<PlayerStatBarChartProps> = ({ data }) => {
  const monthLabels = data
    .map((fp, index) => {
      if (index === 0 || fp.startDate.getMonth() !== data[index - 1].startDate.getMonth()) {
        return format(fp.startDate, "MMM");
      }
      return "";
    })
    .reverse();

  const opponentLabels = data.map((fp) => fp.opponent);

  const fantasyPoints = data.map((fp) => {
    if (fp.played) {
      return fp.fantasyPoint < 1 ? Number.parseFloat(fp.fantasyPoint.toFixed(1)) : Math.round(fp.fantasyPoint);
    }
    return 0; // Default to 0 if played is false
  });

  // Function to get gradients for positive and negative bars
  const getGradients = (ctx: CanvasRenderingContext2D, barTop: number, barBottom: number) => {
    const positiveGradient = ctx.createLinearGradient(0, barBottom, 0, barTop);
    positiveGradient.addColorStop(0, "rgba(31, 28, 28, 0.25)");
    positiveGradient.addColorStop(1, "rgba(0, 226, 24, 0.25)");

    const negativeGradient = ctx.createLinearGradient(0, barBottom, 0, barTop);
    negativeGradient.addColorStop(0, "rgba(31, 28, 28, 0.5)");
    negativeGradient.addColorStop(1, "rgba(255, 229, 0, 0.5)");

    return { positiveGradient, negativeGradient };
  };

  const chartData = {
    labels: monthLabels,
    datasets: [
      {
        data: fantasyPoints,
        backgroundColor: (context: any) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            return fantasyPoints[context.dataIndex] < 0 ? "rgba(255, 229, 0, 0.5)" : "rgba(0, 226, 24, 0.25)";
          }

          const yScale = chart.scales.y;
          const barValue = fantasyPoints[context.dataIndex];
          if (barValue === 0) {
            return "rgba(255, 255, 255, 0.10)";
          }
          const barTop = yScale.getPixelForValue(barValue);
          const barBottom = yScale.getPixelForValue(0);
          const { positiveGradient, negativeGradient } = getGradients(ctx, barTop, barBottom);
          return fantasyPoints[context.dataIndex] < 0 ? negativeGradient : positiveGradient;
        },
        borderColor: (context: any) => {
          return fantasyPoints[context.dataIndex] < 0 ? "rgba(255, 229, 0, 1)" : "rgba(0, 226, 24, 1)";
        },
        borderWidth: (context: any) => {
          return fantasyPoints[context.dataIndex] < 0
            ? { top: 0, left: 0, right: 0, bottom: 1 }
            : { top: 1, left: 0, right: 0, bottom: 0 };
        },
        barThickness: 38,
      },
    ],
  };

  const options = {
    responsive: true,
    layout: {
      padding: {
        bottom: 50, // Makes room for text for negative values
      },
    },
    scales: {
      x: {
        position: "top" as const,
        beginAtZero: true,
        ticks: {
          font: {
            size: 14,
            family: "Arial",
            weight: "bold" as const,
          },
          minRotation: 0,
          maxRotation: 0,
          color: "#ADADAD",
        },
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
      },
      x1: {
        position: "bottom" as const,
        ticks: {
          font: {
            size: 14,
            family: "Arial",
            weight: "bold" as const,
          },
          padding: 10,
          color: "#ADADAD",
        },
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        labels: opponentLabels,
      },
      y: {
        beginAtZero: true,
        min: Math.min(...fantasyPoints, 0),
        max: Math.max(...fantasyPoints, 1),
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
      },
    },
    plugins: {
      annotation: {
        annotations: {
          line1: {
            type: "line" as const,
            yMin: 0,
            yMax: 0,
            color: "rgba(255, 255, 255, 0.10)",
            borderWidth: 1,
            z: 1,
          },
        },
      },
      datalabels: {
        display: true,
        color: "#FFFFFF",
        formatter: (value: number, context: any) => {
          if (!data[context.dataIndex].played) {
            return "DNP";
          }
          return value;
        },
        anchor: (context: any) => {
          return context.dataset.data[context.dataIndex] < 0 ? "start" : "end";
        },
        align: (context: any) => {
          if (!data[context.dataIndex].played) {
            return "end";
          }
          const chart = context.chart;
          const barHeight = Math.abs(
            chart.scales.y.getPixelForValue(fantasyPoints[context.dataIndex]) - chart.scales.y.getPixelForValue(0),
          );
          if (fantasyPoints[context.dataIndex] >= 0) {
            return barHeight < 25 ? "end" : "start";
          }
          return barHeight < 25 ? "start" : "end";
        },
        font: {
          size: 14,
          weight: "bold" as const,
        },
      },
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    barPercentage: 0.8,
    categoryPercentage: 0.9,
  };

  return (
    <div className="w-full h-full">
      <Bar data={chartData} options={options} height={250} />
    </div>
  );
};

export default PlayerStatBarChart;
