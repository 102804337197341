import { CheckIcon, CheckSuccessfulIcon } from "@/components/Icons";
import { TonIcon } from "@/components/Icons";
import { Avatar } from "@/components/Icons/Avatar";
import { TonUsdPill } from "@/components/TonUsdPill";
import { useUserContext } from "@/contexts/UserContext";
import type { EntryResult } from "@/types/Result";
import { cn } from "@/utils";
import { formatNanoTon } from "@/utils/formatNanoTon";
import { useState } from "react";

interface UserTabProps {
  entries: EntryResult[];
  onEntryClick: (entry: EntryResult | null) => void;
  playerImageMap: Record<string, string | null>;
}

export const UserTable = ({ entries, onEntryClick, playerImageMap }: UserTabProps) => {
  const [isOnlyYourTeam, setIsOnlyYourTeam] = useState(false);
  const user = useUserContext();

  const maxFantasyPoint = Math.max(...entries.map((user) => user.fantasyPoint));
  const displayedEntries = isOnlyYourTeam ? entries.filter((entry) => entry.userId === user.id) : entries;

  return (
    <div>
      <div className="flex w-full justify-between items-center py-4">
        <div className="flex gap-1 hover:cursor-pointer" onClick={() => setIsOnlyYourTeam(!isOnlyYourTeam)}>
          {isOnlyYourTeam ? <CheckSuccessfulIcon className="w-4 h-4" /> : <CheckIcon className="w-4 h-4" />}
          <p className="text-xs text-icon">Show only your team</p>
        </div>
        <TonUsdPill />
      </div>
      <div className="flex flex-col w-full gap-1">
        {displayedEntries
          .sort((a, b) => a.rank - b.rank)
          .map((entry) => (
            <EntryRow
              entry={entry}
              maxFantasyPoint={maxFantasyPoint}
              playerImageMap={playerImageMap}
              key={entry.userName}
              onEntryClick={onEntryClick}
              isYourEntry={entry.userId === user.id}
            />
          ))}
      </div>
    </div>
  );
};
export const setRankColor = (rank: number) => {
  switch (rank) {
    case 1:
      return "bg-gradient-to-b from-[#FFE500] to-[#3D4700] bg-clip-text text-transparent text-base font-bold";
    case 2:
      return "bg-gradient-to-b from-[#FFFFFF] to-[#414141] bg-clip-text text-transparent text-base font-bold";
    case 3:
      return "bg-gradient-to-b from-[#CBA080] to-[#693F0D] bg-clip-text text-transparent text-base font-bold";
    default:
      return "text-icon text-xs";
  }
};

const EntryRow = ({
  entry,
  maxFantasyPoint,
  playerImageMap,
  onEntryClick,
  isYourEntry,
}: {
  entry: EntryResult;
  maxFantasyPoint: number;
  playerImageMap: Record<string, string | null>;
  onEntryClick: (entry: EntryResult) => void;
  isYourEntry: boolean;
}) => {
  const { fantasyPoint, rank, userName, prize } = entry;

  const width = (fantasyPoint / maxFantasyPoint) * 130; // 130px is the max width

  const isCaptain = (playerId: string): boolean => {
    return entry.selectedCaptains.captainPlayerId === playerId;
  };

  const isViceCaptain = (playerId: string): boolean => {
    if (entry.selectedCaptains.type === "0002") {
      return entry.selectedCaptains.viceCaptainPlayerId === playerId;
    }
    return false;
  };

  return (
    <div
      className={cn(
        "flex justify-between items-center text-icon text-sm py-2.5 px-2",
        isYourEntry && "bg-gradient-to-r from-[#2A2A2A] to-[#2A2A2A]/0",
      )}
      onClick={() => onEntryClick(entry)}
    >
      <div className="flex items-center w-[60%] gap-2">
        <p className={setRankColor(rank)}>{rank}</p>
        <Avatar />
        <div className="flex flex-col gap-2 w-[60%] flex-grow">
          {isYourEntry ? (
            <div className="flex gap-1 items-center">
              <CheckSuccessfulIcon className="w-4 h-4" />
              <p className="text-sm">{userName}</p>
            </div>
          ) : (
            <p className="text-sm">{userName}</p>
          )}
          <div
            className="h-0.5 bg-icon"
            style={{ width: `${width}px`, boxShadow: rank === 1 ? "0px 0px 9px 0px #FF8A00" : "none" }}
          />
          <div className="flex pl-1.5 pt-1.5 justify-start gap-2 overflow-x-auto w-full hide-scrollbar">
            {entry.selectedPlayers.map((playerId: string) => (
              <div
                className="p-0.5 rounded-md"
                style={{
                  padding: "1px",
                  backgroundImage:
                    isCaptain(playerId) || isViceCaptain(playerId)
                      ? "linear-gradient(to bottom right, #FFE500, #00E218)"
                      : "none",
                  WebkitBackgroundClip: "padding-box",
                  backgroundClip: "padding-box",
                  overflow: "visible",
                }}
                key={playerId}
              >
                <div className="relative bg-chicago-700 rounded-md">
                  {(isCaptain(playerId) || isViceCaptain(playerId)) && (
                    <div
                      className="absolute top-0 left-0 text-xs font-bold text-black rounded-full z-10 flex items-center justify-center"
                      style={{
                        width: "1.75rem",
                        backgroundImage: "linear-gradient(to bottom right, #FFE500, #00E218)",
                        transform: "translate(-25%, -35%)",
                      }}
                    >
                      {isCaptain(playerId) ? "C" : "VC"}
                    </div>
                  )}
                  <img
                    key={playerId}
                    src={playerImageMap[playerId] || undefined}
                    alt="player"
                    className="w-8 h-12 rounded-md select-none cursor-pointer"
                    draggable={false}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 items-center min-w-[140px] w-[35%]">
        <div className="text-center mx-2">{fantasyPoint.toFixed(2)}pt</div>
        <div className="flex items-center">
          <TonIcon className="w-4 h-4 mr-1" />
          <span className="text-center">{formatNanoTon(prize, 4)}</span>
        </div>
      </div>
    </div>
  );
};
