import { BackButton } from "@/components/Buttons/BackButton";
import { Container } from "@/components/Container";
import { CustomModal } from "@/components/CustomModal";
import { Deadline } from "@/components/Deadline";
import { InfoIcon } from "@/components/Icons/InfoIcon";
import { useCountDown } from "@/hooks/useCountDown";
import type { RoundPageType } from "@/pages/Round";
import type { EntryDetails } from "@/types/EntryDetails";
import type { Player } from "@/types/Player";
import type { RoundDetails } from "@/types/RoundDetails";
import { formatName } from "@/utils/name";
import { useCallback, useState } from "react";
import { PrimaryButton } from "../../components/Buttons/PrimaryButton";
import { PlayerFantasyPoints } from "./PlayerFantasyPoints";
import { RoundInfoBanner } from "./RoundInfo/RoundInfoBanner";

interface Props {
  round: RoundDetails;
  setCurrentPage: (page: RoundPageType) => void;
  setEntryDetails: (body: EntryDetails) => void;
  entryDetails: EntryDetails;
  isShowHeader?: boolean;
}

const getEntryDetialType = (type: RoundDetails["type"]): EntryDetails["detail"]["type"] | null => {
  switch (type) {
    case "Round0001":
      return "0001";
    case "Round0002":
      return "0002";
    default:
      return null;
  }
};

export const SelectCaptain: React.FC<Props> = (props) => {
  const { round, entryDetails, setCurrentPage, setEntryDetails, isShowHeader = true } = props;

  const getPlayerById = useCallback((id: string | null, players: Player[]) => {
    if (!id) return null;
    return players.find((player) => player.id === id) ?? null;
  }, []);

  const [captain, setCaptain] = useState<Player | null>(() =>
    getPlayerById(entryDetails.detail.captainPlayerId, [...round.players.homePlayers, ...round.players.awayPlayers]),
  );
  const [viceCaptain, setViceCaptain] = useState<Player | null>(() =>
    getPlayerById(entryDetails.detail.viceCaptainPlayerId, [
      ...round.players.homePlayers,
      ...round.players.awayPlayers,
    ]),
  );

  const [isRoundInfoTabsOpen, setIsRoundInfoTabsOpen] = useState(false);

  if (!round) return null;

  const { deadlineTime } = useCountDown(new Date(round.entryCloseTime));

  const onSelectCaptain = (player: Player) => {
    const type = getEntryDetialType(round.type);

    setEntryDetails({
      ...entryDetails,
      detail: {
        ...entryDetails.detail,
        type,
        captainPlayerId: player.id,
      },
    });
    setCaptain(player);
  };

  const onSelectViceCaptain = (player: Player) => {
    const type = getEntryDetialType(round.type);

    setEntryDetails({
      ...entryDetails,
      detail: {
        ...entryDetails.detail,
        type,
        viceCaptainPlayerId: player.id,
      },
    });
    setViceCaptain(player);
  };

  const isEnterDisabled: boolean = (() => {
    switch (round.type) {
      case "Round0001": {
        if (!captain) return true;
        return false;
      }
      case "Round0002": {
        if (!captain || !viceCaptain) return true;
        return false;
      }
      default:
        return true;
    }
  })();

  return (
    <Container className="flex flex-col">
      {isShowHeader && (
        <div className="flex items-start">
          <BackButton onClick={() => setCurrentPage("select-player")} />
          <div className="flex flex-col w-full">
            <RoundInfoBanner
              round={round}
              isRoundInfoTabsOpen={isRoundInfoTabsOpen}
              onCloseRoundInfoTabs={() => setIsRoundInfoTabsOpen(false)}
              onOpenRoundInfoTabs={() => setIsRoundInfoTabsOpen(true)}
              className="mb-8"
            />
            <Deadline deadlineTime={deadlineTime} />
          </div>
        </div>
      )}

      <div className="mb-8">
        <p className="text-sm text-center text-chicago-200 mb-1">Choose your Captain</p>
        <div className="flex items-center justify-around gap-1.5 bg-[#2A2A2A] py-3.5 px-4 rounded-xl">
          <div className="flex justify-center items-center gap-2">
            {captain ? (
              <img className="w-8 h-12 object-cover" src={captain?.imageUrl || undefined} alt="captain img" />
            ) : (
              <div className="w-8 h-12 bg-button-background rounded-md" />
            )}

            {captain ? (
              <div className="text-xs">
                <p className="text-white">{formatName(captain.name)}</p>
                <p className="text-chicago-200">2X points</p>
              </div>
            ) : (
              <div className="text-xs">
                <p className="text-chicago-200">2X points</p>
              </div>
            )}
          </div>
          {round.type === "Round0002" && (
            <div className="flex justify-center items-center gap-2">
              {viceCaptain ? (
                <img className="w-8 h-12 object-cover" src={viceCaptain.imageUrl || undefined} alt="captain img" />
              ) : (
                <div className="w-8 h-12 bg-button-background rounded-md" />
              )}

              {viceCaptain ? (
                <div className="text-xs">
                  <p className="text-white">{formatName(viceCaptain.name)}</p>
                  <p className="text-chicago-200">2X points</p>
                </div>
              ) : (
                <div className="text-xs">
                  <p className="text-chicago-200">1.5X points</p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <div className="flex flex-col gap-3 mb-16">
        {entryDetails.playerIds.map((playerId) => {
          const player = getPlayerById(playerId, [...round.players.homePlayers, ...round.players.awayPlayers]);
          if (!player) return null;

          return (
            <CaptainSelector
              player={player}
              setCaptain={() => onSelectCaptain(player)}
              captain={captain}
              setViceCaptain={() => onSelectViceCaptain(player)}
              viceCaptain={viceCaptain}
              roundType={round.type}
              roundId={round.id}
              key={player.id}
            />
          );
        })}
      </div>

      <PrimaryButton text="Enter" onClick={() => setCurrentPage("entry")} disabled={isEnterDisabled} />
    </Container>
  );
};

interface CaptainSelectorProps {
  player: Player;
  captain?: Player | null;
  setCaptain: (p: Player) => void;
  viceCaptain?: Player | null;
  setViceCaptain: (p: Player) => void;
  roundType: "Round0001" | "Round0002";
  roundId: string;
}

const CaptainSelector = ({
  player,
  setCaptain,
  captain,
  viceCaptain,
  setViceCaptain,
  roundType,
  roundId,
}: CaptainSelectorProps) => {
  const isCaptain = player.id === captain?.id;
  const isViceCaptain = player.id === viceCaptain?.id;
  const [isPlayerStatsOpen, setIsPlayerStatsOpen] = useState(false);

  if (roundType === "Round0001") {
    return (
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2.5">
          <div
            className="p-0.5 rounded-md"
            style={{
              padding: "1px",
              backgroundImage: isCaptain ? "linear-gradient(to bottom right, #FFE500, #00E218)" : "none",
              WebkitBackgroundClip: "padding-box",
              backgroundClip: "padding-box",
            }}
          >
            <div className="relative bg-chicago-700 rounded-md w-14 h-20">
              {isCaptain && (
                <div
                  className="absolute top-0 left-0 px-3 py-0.5 text-sm font-bold text-black rounded-full z-10 flex items-center justify-center"
                  style={{
                    width: "2.75rem",
                    backgroundImage: "linear-gradient(to bottom right, #FFE500, #00E218)",
                    transform: "translate(-25%, -50%)",
                  }}
                >
                  C
                </div>
              )}
              <img
                draggable={false}
                src={player.imageUrl ?? undefined}
                alt={formatName(player.name)}
                className="absolute inset-0 w-full h-full object-cover rounded-md"
              />
              <InfoIcon
                onClick={() => {
                  setIsPlayerStatsOpen(true);
                }}
                className="absolute bottom-0.5 right-0.5 z-10"
              />
            </div>
          </div>
          <div>
            <p className="text-white text-sm">{formatName(player.name)}</p>
            <p className="text-icon text-xs">{player.averageFantasyPoint.toFixed(2)}pts</p>
          </div>
        </div>
        <div className="flex gap-4 justify-center items-center">
          <SelectPill
            onClick={() => {
              if (player.id !== viceCaptain?.id) setCaptain(player);
            }}
            isSelected={isCaptain}
            forCaptain
          />
        </div>

        <CustomModal
          className="bg-background"
          contentClassName="p-0 w-full h-screen"
          isOpen={isPlayerStatsOpen}
          onClose={() => setIsPlayerStatsOpen(false)}
        >
          <PlayerFantasyPoints player={player} roundId={roundId} />
        </CustomModal>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-2.5">
        <div
          className="p-0.5 rounded-md"
          style={{
            padding: "1px",
            backgroundImage: isCaptain || isViceCaptain ? "linear-gradient(to bottom right, #FFE500, #00E218)" : "none",
            WebkitBackgroundClip: "padding-box",
            backgroundClip: "padding-box",
          }}
        >
          <div className="relative bg-chicago-700 rounded-md w-14 h-20">
            {(isCaptain || isViceCaptain) && (
              <div
                className="absolute top-0 left-0 px-3 py-0.5 text-sm font-bold text-black rounded-full z-10 flex items-center justify-center"
                style={{
                  width: "2.75rem",
                  backgroundImage: "linear-gradient(to bottom right, #FFE500, #00E218)",
                  transform: "translate(-25%, -50%)",
                }}
              >
                {isCaptain ? "C" : "VC"}
              </div>
            )}
            <img
              draggable={false}
              src={player.imageUrl ?? undefined}
              alt={formatName(player.name)}
              className="absolute inset-0 w-full h-full object-cover rounded-md"
            />
            <InfoIcon onClick={() => setIsPlayerStatsOpen(true)} className="absolute bottom-0.5 right-0.5 z-10" />
          </div>
        </div>
        <div>
          <p className="text-white text-sm">{formatName(player.name)}</p>
          <p className="text-icon text-xs">{player.averageFantasyPoint.toFixed(2)}pts</p>
        </div>
      </div>
      <div className="flex gap-4 justify-center items-center">
        <SelectPill
          onClick={() => {
            if (player.id !== viceCaptain?.id) setCaptain(player);
          }}
          isSelected={isCaptain}
          forCaptain
        />
        <SelectPill
          onClick={() => {
            if (player.id !== captain?.id) setViceCaptain(player);
          }}
          isSelected={isViceCaptain}
          forCaptain={false}
        />
      </div>

      <CustomModal
        className="bg-background"
        contentClassName="p-0 w-full h-screen"
        isOpen={isPlayerStatsOpen}
        onClose={() => setIsPlayerStatsOpen(false)}
      >
        <PlayerFantasyPoints player={player} roundId={roundId} />
      </CustomModal>
    </div>
  );
};

export const SelectPill = ({
  onClick,
  isSelected,
  forCaptain,
}: { onClick: () => void; isSelected: boolean; forCaptain: boolean }) => {
  return (
    <div
      className={`flex items-center justify-center rounded-[30px] 
        p-2.5 w-10 h-8 hover:cursor-pointer ${isSelected ? "bg-gradient-to-r from-gradient-yellow to-gradient-green" : "bg-chicago-700"}
  `}
      onClick={onClick}
      onKeyDown={(e) => {
        if (e.key === "enter") onClick();
      }}
    >
      {isSelected ? (
        <p className="text-[#333333] text-xs">{forCaptain ? "2X" : "1.5X"}</p>
      ) : (
        <p className="text-white text-xs">{forCaptain ? "C" : "VC"}</p>
      )}
    </div>
  );
};
