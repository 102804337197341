import { CustomModal } from "@/components/CustomModal";
import { InfoIcon } from "@/components/Icons";
import type { Player } from "@/types/Player";
import type { CaptainDetail } from "@/types/Schedule";
import { cn } from "@/utils";
import { formatName } from "@/utils/name";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PlayerFantasyPoints } from "../Round/PlayerFantasyPoints";

interface PlayerTabProps {
  entryId: string;
  roundId: string;
  selectedPlayers: Player[];
  selectedCaptains: CaptainDetail;
  roundEntryUnitId: string;
}

export const PlayerTable = ({
  entryId,
  roundId,
  selectedPlayers,
  selectedCaptains,
  roundEntryUnitId,
}: PlayerTabProps) => {
  const [isPlayerStatsOpen, setIsPlayerStatsOpen] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState<Player | null>(null);

  return (
    <>
      <div className="flex flex-col">
        {selectedPlayers.map((player) => {
          return (
            <MyTeamPlayerRow
              player={player}
              key={player.id}
              selectedCaptains={selectedCaptains}
              roundEntryUnitId={roundEntryUnitId}
              entryId={entryId}
              onClick={(player) => {
                setIsPlayerStatsOpen(true);
                setSelectedPlayer(player);
              }}
            />
          );
        })}
      </div>

      <CustomModal
        className="bg-background"
        contentClassName="p-0 w-full h-screen"
        isOpen={isPlayerStatsOpen}
        onClose={() => {
          setIsPlayerStatsOpen(false);
          setSelectedPlayer(null);
        }}
      >
        {selectedPlayer && <PlayerFantasyPoints player={selectedPlayer as Player} roundId={roundId} />}
      </CustomModal>
    </>
  );
};

interface PlayerRowProps {
  player: Player;
  selectedCaptains: CaptainDetail;
  roundEntryUnitId: string;
  entryId: string;
  onClick: (player: Player) => void;
}

const MyTeamPlayerRow = ({ player, selectedCaptains, roundEntryUnitId, entryId, onClick }: PlayerRowProps) => {
  const navigate = useNavigate();
  const matchName = new URLSearchParams(location.search).get("matchName") ?? "";

  const isCaptain = selectedCaptains.captainPlayerId === player.id;
  const isViceCaptain = selectedCaptains.type === "0002" && selectedCaptains.viceCaptainPlayerId === player.id;

  return (
    <div className="flex justify-between items-center w-full px-4 py-1.5 text-icon">
      <div
        className="flex gap-3 items-center cursor-pointer"
        onClick={() =>
          navigate(
            `/scheduled/${roundEntryUnitId}/entries/${entryId}?playerId=${player.id}&matchName=${encodeURIComponent(matchName)}`,
          )
        }
      >
        <div
          className="p-0.5 rounded-md"
          style={{
            padding: "1px",
            backgroundImage: isCaptain || isViceCaptain ? "linear-gradient(to bottom right, #FFE500, #00E218)" : "none",
            WebkitBackgroundClip: "padding-box",
            backgroundClip: "padding-box",
          }}
        >
          <div className="relative bg-chicago-700 rounded-md w-14 h-20">
            {(isCaptain || isViceCaptain) && (
              <div
                className="absolute top-0 left-0 px-3 py-0.5 text-sm font-bold text-black rounded-full z-10 flex items-center justify-center"
                style={{
                  width: "2.75rem",
                  backgroundImage: "linear-gradient(to bottom right, #FFE500, #00E218)",
                  transform: "translate(-25%, -50%)",
                }}
              >
                {isCaptain ? "C" : "VC"}
              </div>
            )}
            <img
              src={player.imageUrl || undefined}
              alt="player"
              className="w-full h-full rounded-md select-none cursor-pointer"
              draggable={false}
            />

            <InfoIcon
              onClick={() => {
                onClick(player);
              }}
              className="absolute bottom-[2px] right-1 z-10 w-4"
            />
          </div>
        </div>

        <div>
          <p className="text-sm text-white">{formatName(player.name)}</p>
          <p className="text-icon text-xs">{player.averageFantasyPoint.toFixed(2)}pts</p>
        </div>
      </div>

      <div className="flex gap-4 justify-center items-center">
        <div
          className={cn(
            "flex items-center justify-center rounded-[30px] p-2.5 w-10 h-8 hover:cursor-pointer",
            isCaptain ? "bg-gradient-to-r from-gradient-yellow to-gradient-green" : "bg-chicago-700",
          )}
        >
          {isCaptain ? <p className="text-[#333333] text-xs">2X</p> : <p className="text-white text-xs">C</p>}
        </div>

        {selectedCaptains?.type === "0002" && (
          <div
            className={cn(
              "flex items-center justify-center rounded-[30px] p-2.5 w-10 h-8 hover:cursor-pointer",
              isViceCaptain ? "bg-gradient-to-r from-gradient-yellow to-gradient-green" : "bg-chicago-700",
            )}
          >
            {isViceCaptain ? <p className="text-[#333333] text-xs">1.5X</p> : <p className="text-white text-xs">VC</p>}
          </div>
        )}
      </div>
    </div>
  );
};
